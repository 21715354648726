import { Children, useContext, useEffect } from "react";
import { WizardContext } from "./store/wizard.store";
import { validateWizardFormData } from "./utils/ValidationUtil";

export const Wizard: React.FC = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const pages = Children.toArray(props.children);
    const currentPage = pages[wizardFormData.activePage];

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const title = document.getElementsByTagName("h1")[0];
            //59 is the height of the header
            window.scrollTo(title.offsetLeft, title.offsetTop - 59);
        }
    }, [wizardFormData.activePage])

    const goNextPage = () => {
        //Validate all fields before going to the next step
        let ids = ["personalNumber", "getInformationFromSkatteverket", "eMail", "mobilePhonenumber", "messageForClinic", ""];

        let errors = {};

        for (let id of ids) {
            errors = { ...errors, ...validateWizardFormData(wizardFormData, id) };
        }

        let firstErrorKey = Object.keys(errors)[0];
        //Focus the first field with validation errors if any
        if (firstErrorKey) {
            if (firstErrorKey === "getInformationFromSkatteverket") firstErrorKey = "personalNumber";

            const errorElement = document.getElementById(firstErrorKey);
            if (errorElement) {
                errorElement.focus();
            }
        }

        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }));

        if (Object.values(errors).length == 0) setWizardFormData((prevState) => ({ ...prevState, activePage: prevState.activePage + 1 }));
    };

    const goPrevPage = () => {
        // If user changes treatment type we want to clear the data for the next step
        if ((wizardFormData.activePage == 1 || wizardFormData.activePage == 2) && wizardFormData.selectedClinics || wizardFormData.searchResult) {
            setWizardFormData({ ...wizardFormData, selectedClinics: [], searchResult: [], showSearchResult: false, showRelatedClinics: false, isBookingWithCode: false });
        }
        setWizardFormData((prevState) => ({ ...prevState, activePage: prevState.activePage - 1 }));
    };

    const goToPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        const currentId = parseInt(e.currentTarget.id);
        // If user changes treatment type we want to clear the data for the next step
        if (currentId == 0 && wizardFormData.selectedClinics || wizardFormData.searchResult) {
            setWizardFormData({ ...wizardFormData, selectedClinics: [], searchResult: [], showSearchResult: false, showRelatedClinics: false, isBookingWithCode: false });
        }
        setWizardFormData((prevState) => ({ ...prevState, activePage: currentId }))
    }

    const ButtonPrev = () =>
        wizardFormData.activePage > 0 ? (
            <button
                type="button"
                onClick={goPrevPage}
                className="g-col-6 g-col-md-2"
            >
                Gå tillbaka
            </button>
        ) : null;

    const ButtonNext = () => {
        // We only want to show "Gå vidare" button on the third step, and "Boka tiden" button on the last step
        if (wizardFormData.activePage == 2) {
            return (
                <button
                    type="button"
                    onClick={goNextPage}
                    className="g-col-6 g-col-md-2 button-primary button-navigation"
                    disabled={Object.values(wizardFormData.validationErrors).length > 0}
                >
                    Gå vidare
                </button>
            )
        } else if (wizardFormData.activePage == pages.length - 1) {
            return (
                <button
                    type="submit"
                    className="g-col-6 g-col-md-2 button-primary button-navigation"
                    disabled={Object.values(wizardFormData.validationErrors).length > 0}
                >
                    Boka tiden
                </button>
            )
        } else {
            return null;
        }
    }

    const StepText = () => {
        if (wizardFormData.activePage == 0) return "Välj behandling";
        else if (wizardFormData.activePage == 1) return "Klinik och tid";
        else if (wizardFormData.activePage == 2) return "Mina uppgifter";
        else if (wizardFormData.activePage == 3) return "Bekräfta och boka";
    }

    const NextStepText = () => {
        if (wizardFormData.activePage == 0) return "Sök tider";
        else if (wizardFormData.activePage == 1) return "Personuppgifter";
        else if (wizardFormData.activePage == 2) return "Bekräfta och boka";
    }

    const activeClass = (step: number) => {
        if (wizardFormData.activePage == step) return "wizardstep g-col-3 active";
        else if (wizardFormData.activePage >= step) return "wizardstep g-col-3 prevactive";
        else return "wizardstep g-col-3";
    }

    return (
        <div className="grid g-col-12">
            <div className="grid g-col-12" style={{ rowGap: "1rem" }}>
                <ol className="wizardsteps g-col-12">
                    <div className="wizardstep__container">
                        <li className="wizardstep">
                            <button type="button" id="0" onClick={goToPage} className={wizardFormData.activePage == 0 ? "button-primary" : ""}>1</button>
                            <i className="wizardstep__arrow"></i>
                        </li>
                        <strong>Välj behandling</strong>
                    </div>
                    <div className="wizardstep__container">
                        <li className="wizardstep">
                            <button type="button" id="1" onClick={goToPage} disabled={wizardFormData.activePage < 1} className={wizardFormData.activePage == 1 ? "button-primary" : ""}>2</button>
                            <i className="wizardstep__arrow"></i>
                        </li>
                        <strong>Klinik och tid</strong>
                    </div>
                    <div className="wizardstep__container">
                        <li className="wizardstep">
                            <button type="button" id="2" onClick={goToPage} disabled={wizardFormData.activePage < 2} className={wizardFormData.activePage == 2 ? "button-primary" : ""}>3</button>
                            <i className="wizardstep__arrow"></i>
                        </li>
                        <strong>Mina uppgifter</strong>
                    </div>
                    <div className="wizardstep__container">
                        <li className="wizardstep">
                            <button type="button" id="3" onClick={goToPage} disabled={wizardFormData.activePage < 3} className={wizardFormData.activePage == 3 ? "button-primary" : ""}>4</button>
                        </li>
                        <strong>Bekräfta och boka</strong>
                    </div>
                </ol>
            </div>
            <div className="wizardsteps--mobile g-col-12">
                <h2>
                    Steg {wizardFormData.activePage + 1} av 4: {StepText()}
                </h2>
                {wizardFormData.activePage !== 3 && <p>Nästa: {NextStepText()}</p>}
            </div>
            <div className="wizardprogress g-col-12">
                <ul className="g-col-12">
                    <li className={activeClass(0)}>
                        <span></span>
                    </li>
                    <li className={activeClass(1)}>
                        <span></span>
                    </li>
                    <li className={activeClass(2)}>
                        <span></span>
                    </li>
                    <li className={activeClass(3)}>
                        <span></span>
                    </li>
                </ul>
            </div>
            <div className="g-col-12">
                {currentPage}
            </div>
            <div className="grid g-col-12">
                <ButtonPrev />
                <ButtonNext />
            </div>
        </div>
    )
}