import React, { useEffect, useState, useRef } from 'react';
import { RegionCalendarItem } from './RegionCalendarItem';
import { Options, Splide, SplideSlide } from "@splidejs/react-splide";

export const RegionCalendarRssBlock = (props: RegionCalendarRssBlockProps) => {
    const splideRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        // Add event listener to update the current slide index when slides change
        const splide = splideRef.current?.splide;
        if (splide) {
            splide.on("moved", (newIndex) => {
                setCurrentSlide(newIndex);
            });
        }

        return () => {
            if (splide) {
                splide.off("moved");
            }
        };
    }, []);

    const next = (
        <svg
            id="alert"
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            width="18"
            aria-hidden="true"
            focusable="false"
            className="svg-next">
            <use href="#streamline-arrow" />
        </svg>
    );

    const previous = (
        <svg
            id="alert"
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            width="18"
            aria-hidden="true"
            focusable="false"
            className="svg-prev">
            <use href="#streamline-arrow" />
        </svg>
    );

    const CustomSplideControlsMobile = () => (
        <div className="splide-controls splide-controls--mobile">
            <button className="splide__arrow splide__arrow--prev" disabled={currentSlide === 0} onClick={() => splideRef.current?.splide?.go('<')} dangerouslySetInnerHTML={{ __html: props.chevronIcon }}>
            </button>
            <button className="splide__arrow splide__arrow--next" disabled={currentSlide >= props.listPages.length - 1} onClick={() => splideRef.current?.splide?.go('>')} dangerouslySetInnerHTML={{ __html: props.chevronIcon }}>
            </button>
        </div>
    );

    const CustomSplideControls = () => (
        <div className="splide-controls">
            <button className="splide__arrow splide__arrow--prev" aria-label="Förgående kalenderhändelse" disabled={currentSlide === 0} onClick={() => splideRef.current?.splide?.go('<')} dangerouslySetInnerHTML={{ __html: props.chevronIcon }}>
            </button>
            <button className="splide__arrow splide__arrow--next" aria-label="Nästa kalenderhändelse" disabled={currentSlide >= props.listPages.length - 4} onClick={() => splideRef.current?.splide?.go('>')} dangerouslySetInnerHTML={{ __html: props.chevronIcon }}>
            </button>
        </div>
    );

    const splideOptions: Options = {
        perPage: 4,
        perMove: 4,
        breakpoints: {
            540: {
                width: "352px",
                fixedWidth: "",
                padding: { left: 0, right: "2rem" },
                perPage: 1,
                perMove: 1
            },
        },
        fixedWidth: "calc(25% - 2rem)",
        arrows: false,
        pagination: false,
        label: `Bildspel med ${props.listPages.length} kalenderhändelser.`,
    };

    return (
        <>
            <div className="calendar-block__header">
                <h2 id="calendar-block__title">
                    {props.heading}
                </h2>
                <CustomSplideControlsMobile />
            </div>
            <div>
                <div data-index-exclude className="calendar-block">
                    <Splide className="calendar-block__slider" options={splideOptions} ref={splideRef}>
                        {props.listPages &&
                            props.listPages.map((item) => (
                                <SplideSlide key={item.id} className="calendar-block__slide">
                                    <RegionCalendarItem
                                        imageSourceSets={item.imageSourceSets}
                                        imageDesktopUrl={item.imageDesktopUrl}
                                        eventStart={item.eventStart}
                                        eventStop={item.eventStop}
                                        title={item.title}
                                        pageLink={item.pageLink}
                                        splashColor={props.splashColor}
                                    />
                                </SplideSlide>
                            ))}
                    </Splide>
                    <div className="calendar-block__footer">
                        <CustomSplideControls />
                        <a className="calendar-block__link g-col-12" href={props.calendarHyperlink.href}>{props.calendarHyperlink.text}</a>
                    </div>
                </div>
            </div>
        </>
    );
};
