import { Wizard } from "./Wizard"
import { WizardSearchStep } from "./WizardSearchStep";
import { WizardPersonalDataStep } from "./WizardPersonalDataStep";
import { WizardConfirmationStep } from "./WizardConfirmationStep";
import { WizardTreatmentType } from "./WizardTreatmentType";

export const WizardContainer: React.VFC<WizardContainerProps> = (props) => {
    return (
        <form action={props.postUrl} method="post" className="grid g-col-12 vgr-form">
            <input
                type="hidden"
                name="__RequestVerificationToken"
                id="__RequestVerificationToken"
                value={props.antiforgeryToken}
            ></input>
            <Wizard>
                <WizardTreatmentType
                    treatmentTypes={props.treatmentTypes}
                    chevronIconSvg={props.chevronIconSvg}
                    infoTextFtvOnline={props.infoTextFtvOnline}
                    ftvOnlineLink={props.ftvOnlineLink}
                    infoTextBookingCode={props.infoTextBookingCode}
                    infoTextTreatmentTypes={props.infoTextTreatmentTypes}
                    ftvOnlineTitle={props.ftvOnlineTitle}
                    titleBookingCode={props.titleBookingCode}
                    antiforgeryToken={props.antiforgeryToken}
                />
                <WizardSearchStep
                    antiforgeryToken={props.antiforgeryToken}
                    searchResultIcon={props.searchResultIcon}
                    infoTextSearchResult={props.infoTextSearchResult}
                    infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                    lastMinuteResultIcon={props.lastMinuteResultIcon}
                    activateLastMinute={props.activateLastMinute}
                    additionalClinicInformation={props.additionalClinicInformation}
                    dentistTreatmentTypeId={props.dentistTreatmentTypeId}
                    hygienistTreatmentTypeId={props.hygienistTreatmentTypeId}
                    majorTroubleTreatmentTypeId={props.majorTroubleTreatmentTypeId}
                    calendarIcon={props.calendarIcon}
                    placeAndPerformerIcon={props.placeAndPerformerIcon}
                    activateSmartSearch={props.activateSmartSearch}
                />
                <WizardPersonalDataStep
                    antiforgeryToken={props.antiforgeryToken}
                    infoTextMessageToClinic={props.infoTextMessageToClinic}
                    infoTextGetPersonalData={props.infoTextGetPersonalData}
                    personalInfoUrl={props.personalInfoUrl}
                />
                <WizardConfirmationStep />
            </Wizard>
        </form>
    )
}