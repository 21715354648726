import React, { useLayoutEffect, useRef, useState } from "react";
import { SearchResponse } from "../shared/shared.models";
import { StyrandeDokument } from "./models";
import {
  ActiveSearchFilters,
  CheckboxTree,
  NoHits,
  Pagination,
  SearchHeader,
  ToggleList,
} from "../shared/components";
import { ClientOnly, useClientOnly, useSearchEngine } from "../shared/hooks";
import { SearchResultItem } from "./SearchResultItem";
import { SearchResultVardhandboken } from "./SearchResultVardhandboken";
import {
  selectActiveFilters,
  selectIsLoading,
  selectSearchResult,
} from "../shared/store/shared.reducer";
import { useAppSelector } from "../shared/store/store.hooks";
import { Sorting } from "../shared/components/Sorting";
export const StyrandeDokumentComponent: React.VFC = () => {
  /*********************************
   * Variables
   *********************************/
  const pathName =
    typeof window !== "undefined" ? window.location.pathname : ""; // En tillfällig fix. Sökningen kommer inte att fungera utan javascript, eftersom vi behöver sökvägen till sidan.
  const [doSearch] = useSearchEngine(pathName  + "/FilterStyrandeDokument");

  const isFirstRender = useRef(true);
  const [isFilterSectionOpen, setFilterSectionOpen] = useState(false);

  const searchResult = useAppSelector(
    selectSearchResult
  ) as SearchResponse<StyrandeDokument>;
  const isLoading = useAppSelector(selectIsLoading);
  const activeFiltersCount = useAppSelector(selectActiveFilters).length;

  const { numberOfHits, sortOptions, pagination } = searchResult.documentList;
  const facet = searchResult.facet;
  const subjectFacets = searchResult.subjectFacets;
  const hidePagination = pagination.pages.length == 0;

  const searchResultClassList = isLoading
    ? "vgr-searchservice__results loading"
    : "vgr-searchservice__results";

  const isHydrated = useClientOnly();

  /*********************************
   * Functions
   *********************************/
  function toggleFilterPanel() {
    document.getElementsByTagName("html")[0].classList.toggle("backdrop");
    setFilterSectionOpen(!isFilterSectionOpen);
  }

  const getSearchQuery = () => {
    const queryString = searchResult.query.queryString;
    if (queryString !== "*") return ` - "${searchResult.query.queryString}"`;
    return "";
  };

  useLayoutEffect(() => {
    isFirstRender.current = false;
  }, []);

  /*********************************
   * Render
   *********************************/
  return (
    <div className="vgr-searchservice">
      <SearchHeader />

      <ClientOnly>
        <section
          id="searchfiltersection"
          aria-label="Sektion med olika valbara sökfilter"
          className="vgr-searchservice__filters"
          data-firstrender={isFirstRender.current}
          data-open={isFilterSectionOpen}
        >
          <div className="vgr-searchservice__closefilter">
            <button
              className="vgr-searchservice__linkbutton"
              onClick={() => toggleFilterPanel()}
              type="button"
            >
              {chevron} Stäng
            </button>
          </div>
          <ActiveSearchFilters />

          <ToggleList config={searchResult.includesFilters} />

          {facet.children?.length > 0 && (
            <CheckboxTree
              heading={facet.displayName}
              tight={true}
              treeId={facet.id}
              items={facet.children}
            />
          )}

          {subjectFacets.filters?.length > 0 && (
            <CheckboxTree
              heading={subjectFacets.displayName}
              tight={true}
              treeId={subjectFacets.id}
              items={subjectFacets.filters}
              maxTopLevelItems={7}
            />
          )}

          <div className="vgr-searchservice__mobile-filter-show-documents">
            <button type="button" onClick={() => toggleFilterPanel()}>
              Visa {numberOfHits} dokument
            </button>
          </div>
        </section>
      </ClientOnly>

      <Sorting
        isHydrated={isHydrated}
        sortList={sortOptions}
        isLoading={isLoading}
        numberOfHits={numberOfHits}
        mobileFormClassName="vgr-searchservice__resultsorting-mobile"
        endPoint={pathName + "/FilterStyrandeDokument"}
        hiddenClassName=""
        sectionClassName="vgr-searchservice__resultsheadersection"
        resultHeaderClassName="vgr-searchservice__resultsheader"
        searchQuery={getSearchQuery()}
        desktopResultSortingClassName="vgr-searchservice__resultsorting"
        buttonClassName="vgr-searchservice__linkbutton"
        hasMobileButton={true}
        isFilterSectionOpen={isFilterSectionOpen}
        setFilterSectionOpen={setFilterSectionOpen}
        activeFiltersCount={activeFiltersCount}
        filterIcon={filterIcon}
      />

      <section
        tabIndex={0}
        aria-label="Sektion där sökresultat presenteras"
        id="searchResultList"
        className={
          isHydrated
            ? searchResultClassList
            : searchResultClassList + " ssr-only"
        }
      >
        {searchResult.documentList.documents.map((x) =>
          x.source === "Vårdhandboken" ? (
            <SearchResultVardhandboken
              key={x.id + searchResult.query.parameters}
              item={x}
            />
          ) : (
            <SearchResultItem
              key={x.id + searchResult.query.parameters}
              item={x}
            />
          )
        )}
        {searchResult.documentList.numberOfHits === 0 && <NoHits />}
        {searchResult.documentList.numberOfHits > 0 && (
          <div className="vgr-searchservice__box vgr-searchservice__infobox">
            <div>
              <strong>Hittar du inte det du söker?</strong>
            </div>
            <p className="full-width-paragraph">
              Här visas bara de dokument som upprättats som styrande dokument.
              Checklistor, blanketter och andra stödjande dokument finns i Hitta
              i arkivet. Där kan du också hitta dokument med begränsad
              åtkomsträtt, enbart för din myndighet.
            </p>

            <a href="https://hittaiarkivet.vgregion.se">
              Sök i hittaiarkivet.vgregion.se
            </a>
          </div>
        )}
        {!hidePagination && (
          <Pagination
            scrollToId="searchResultsHeaderSection"
            cssClass="vgr-searchservice"
            endPoint={pathName + "/FilterStyrandeDokument"}
            searchResult={searchResult}
          />
        )}
      </section>
    </div>
  );
};

const chevron = (
  <svg aria-hidden={true} style={{ width: "16px", height: "16px" }}>
    <use xlinkHref="#streamline-arrow"></use>
  </svg>
);

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.7471 5.25H22.2471C23.0755 5.25 23.7471 5.92157 23.7471 6.75V21.75C23.7471 22.5784 23.0755 23.25 22.2471 23.25H8.74707C7.91864 23.25 7.24707 22.5784 7.24707 21.75V15.75"
      stroke="#005C95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.2471 9.75H20.7501"
      stroke="#005C95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9971 14.25H20.7501"
      stroke="#005C95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 18.75H20.75"
      stroke="#005C95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75004 9.75V6.75L12.939 2.561C13.2423 2.25756 13.333 1.80138 13.1688 1.40504C13.0047 1.0087 12.618 0.750194 12.189 0.75H2.31104C1.88205 0.750194 1.4954 1.0087 1.33126 1.40504C1.16712 1.80138 1.2578 2.25756 1.56104 2.561L5.75004 6.75V12.75L8.75004 9.75Z"
      stroke="#005C95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
