import React, { useEffect, useState } from "react";

import { OrganisationSearchItem } from "./models";
import { useClientOnly } from "../shared/hooks";
import { OrganisationSearchResultItem } from "./OrganisationSearchResultItem";
import {
  selectIsLoading,
  selectSearchResponse,
} from "../shared/store/shared.reducer";
import { useAppSelector } from "../shared/store/store.hooks";
import { Sorting } from "../shared/components/Sorting";
import { QueryEntity, SearchResult } from "../shared/shared.models";
import { Clinic } from "../../../../../Cms/Static/js/visuell-identitet/components/leaflet-map";

import { useLocation } from "react-router-dom";

interface Props {
  initialQuery: string;
  chevronIconSvg: string;
  isPost: string;
}

export const OrganisationSearchDocument: React.VFC<Props> = ({
  chevronIconSvg,
  isPost,
}) => {
  const isHydrated = useClientOnly();
  const isLoading = useAppSelector(selectIsLoading);
  let index = 0;
  const [isFilterSectionOpen, setIsFilterSectionOpen] =
    useState<boolean>(false);
  
  const [coords, setCoords] = useState<string>();

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoords(position.coords.latitude.toString() + ',' + position.coords.longitude.toString()); 
    });
  }

  const searchResult = useAppSelector(
    selectSearchResponse
  ) as SearchResult<OrganisationSearchItem>;
  const numberOfHits = searchResult?.components?.doclists[0]?.documents.length;
  const sortOptions: QueryEntity[] = [];

  const location = useLocation();
  // Funktion för att öppna eller stänga filtersektionen
  const toggleFilterSection = () => {
    setIsFilterSectionOpen((prevState) => !prevState);
  };
  useEffect(() => {
    let markerData: Clinic[] = [];
    if (
      searchResult !== undefined &&
      searchResult.components.doclists != undefined &&
      searchResult.components.doclists[0].documents.length > 0
    ) {
      markerData = searchResult.components.doclists[0].documents.map(
        (x) =>
          ({
            id: x.id,
            name: x.title,
            position: [
              parseFloat(
                x.coordinates != undefined
                  ? x.coordinates?.coordinatesX
                  : x.latlon_0_coordinate
              ),
              parseFloat(
                x.coordinates != undefined
                  ? x.coordinates?.coordinatesY
                  : x.latlon_1_coordinate
              ),
            ],
            url: x.url,
            address: x.streetaddress,
            phone: x.phonenumber,
          } as Clinic)
      );
    }
    // Dispatch a custom event when markerPositions change.
    const event = new CustomEvent("markerPositionsChanged", {
      detail: {
        markerPositions: markerData,
      },
    });
    window.dispatchEvent(event);
  }, [searchResult]);

  const searchParametersUrl = new URLSearchParams(location.search);

  const hasSort = searchParametersUrl.get("sort");
  const hasPosition = searchParametersUrl.get('position');

  searchParametersUrl.delete("sort");
  const stringSearchParameters = searchParametersUrl.toString();

  searchResult?.components?.sortOptions?.forEach((item) => {
    const value = new URLSearchParams(item.query);
    const option = { ...item, value };
    if (isPost) {
      if (item.displayName == "Title A-Z") {
        option.displayName = "A-Ö";
        option.value = value;
        const queryEntity: QueryEntity = {
          displayName: option.displayName,
          query: option.query,
          selected: option.applied,
          defaultDisabled: false,
        };
        if (
          sortOptions.find((x) => x.displayName == option.displayName) ==
          undefined
        )
          sortOptions.push(queryEntity);
      } else if(item.displayName == "Nearest" && (hasPosition || coords)){
        option.displayName = "Avstånd";
        option.value = value;
        const queryEntity: QueryEntity = {
          displayName: option.displayName,
          query: option.query,
          selected: option.applied,
          defaultDisabled: false,
        };
        if(sortOptions.find((x) => x.displayName == option.displayName) == undefined){
          sortOptions.push(queryEntity);
        }
      }
      else {
        const queryEntity: QueryEntity = {
          displayName: "Relevans",
          query: stringSearchParameters,
          selected:
            hasSort != "" && hasSort != undefined && hasSort != null
              ? false
              : true,
          defaultDisabled: false,
        };
        if (sortOptions.find((x) => x.displayName == "Relevans") == undefined)
          sortOptions.push(queryEntity);
      }
    }
  });

  return (
    <div className={`organization-search-block ${isLoading ? "loading" : ""}`}>
      {isPost || searchResult == null ? (
        <>
          {searchResult !== undefined &&
          searchResult.components.doclists != undefined &&
          searchResult.components.doclists[0].documents.length > 0 ? (
            <>
              <div className="">
                <Sorting
                  isHydrated={isHydrated}
                  sortList={sortOptions}
                  isLoading={isLoading}
                  numberOfHits={numberOfHits}
                  mobileFormClassName="search-result-block__sorting-mobile"
                  endPoint="Search/FilterOrganisationSearchDocument"
                  hiddenClassName="visually-hidden"
                  resultHeaderClassName="search-result-block__sorting-container"
                  searchQuery=""
                  sectionClassName=""
                  desktopResultSortingClassName="search-result-block__sorting"
                  buttonClassName="link-focus"
                  hasMobileButton={false}
                  isFilterSectionOpen={false}
                  setFilterSectionOpen={toggleFilterSection}
                  activeFiltersCount={0}
                  filterIcon={null}
                />
              </div>
            </>
          ) : (
            <div></div>
          )}
          <div className="search-result-block__search-result-map-wrapper">
            {searchResult !== undefined &&
            searchResult.components.doclists != undefined &&
            searchResult.components.doclists[0].documents.length > 0 ? (
              <>
                <div
                  className="search-result-block search-result-block__search-result-wrapper g-col-md-6 g-col-12"
                  id="scroll-container"
                >
                  <ul className="grid search-result-block__search-result-list">
                    {searchResult?.components?.doclists[0]?.documents.map(
                      (x) => (
                        <OrganisationSearchResultItem
                          key={x.id + index++}
                          item={x}
                          chevronIconSvg={chevronIconSvg}
                        />
                      )
                    )}
                  </ul>
                </div>
              </>
            ) : (
              <div className="search-result-block g-col-md-6 g-col-12">
                Din sökning genererade inga träffar, förfina din sökning och
                försök igen.
              </div>
            )}
            <div className="search-result-block__map-wrapper  g-col-md-6 g-col-12 ">
              <div
                className="search-result-block__map"
                id="search-result-map"
              ></div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
