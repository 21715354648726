import React from 'react';

export const RegionCalendarItem = (props: RegionCalendarItemProps) => {

    const getDate = () => {
        const eventStart = new Date(props.eventStart);
        const eventStop = new Date(props.eventStop);
        const now = new Date();
        while (eventStart < now && eventStart < eventStop) {
            eventStart.setDate(eventStart.getDate() + 1);
        }
        return (
            <>
                {eventStart.toLocaleDateString('sv-SE', { weekday: 'long', day: 'numeric', month: 'long' }).replace(/^\w/, c => c.toUpperCase())}
                <br />
                {eventStart.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) + ' - '}
                {eventStop.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })}
            </>
        );
    }

    return (
        <article data-index-exclude className="teaser-block bg-white g-col-12 g-col-md-3 calendar-block__teaser">
            <div>
                {props.imageDesktopUrl ? <img alt="" className="teaser-block__image"
                    srcSet={props.imageSourceSets && props.imageSourceSets.join(", ")}
                    sizes="(min-width: 770px) 880px, 100vw"
                    src={props.imageDesktopUrl} /> :
                    <img alt="" loading='lazy' className="teaser-block__image" src="/images/dekor_950x407_grey.png" />}

                <div className={"teaserblock__image teaserblock__image--small teaser-block__small-icon--background calendar-block__date-splash " + props.splashColor}>
                    <time dateTime={new Date(props.eventStart).toLocaleString('sv-SE')} className="calendar-block__date-container">
                        <span className="calendar-block__date-day">
                            {new Date(props.eventStart).getDate().toString().padStart(2, '0')}
                        </span>
                        <span className="calendar-block__date-month">
                            {new Date(props.eventStart).toLocaleString('sv-SE', { month: 'short' }).replace('.', '')}
                        </span>
                    </time>
                </div>

            </div>
            <div className="teaser-block__content-wrapper">
                <div className="teaser-block__text-content">
                    <a href={props.pageLink} className="ignore-link-focus">
                        <h3>
                            {props.title}
                        </h3>
                    </a>
                    <div>
                        {getDate()}
                    </div>
                </div>
            </div>
        </article>
    );
};
