import React from "react";
import ReactPaginate from "react-paginate";
import { useSearchEngine } from "../hooks";
import { SearchResponse } from "../shared.models";

interface Page {
  displayName: string;
  selected: boolean;
  query: string;
}

interface Props<TDocumentType = unknown> {
  scrollToId: string;
  cssClass: string;
  endPoint: string;

  searchResult: SearchResponse<TDocumentType>;
}

export const Pagination: React.VFC<Props> = ({
  scrollToId,
  cssClass,
  endPoint,
  searchResult,
}) => {
  /*********************************
   * Variables
   *********************************/
  // const searchResult = useAppSelector(selectSearchResult);
  const pagination = searchResult.documentList.pagination;
  // Find selected page, grab the displayName and convert to integer using (+), then subtract 1 to correspond to a 0-based index
  const initialPageIndex =
    pagination.pages.length > 0
      ? +pagination.pages.find((p) => p.selected).displayName - 1
      : 0;
  const totalPagesCount = calculateNumberOfTotalPages();
  const pages = constructArrayOfPageModels(totalPagesCount);

  const [doSearch] = useSearchEngine(endPoint);

  /*********************************
   * Functions
   *********************************/
  function calculateNumberOfTotalPages() {
    const lastPageQuery = pagination.lastPage?.query;
    const lastPageInCollectionQuery =
      pagination.pages.length > 0
        ? pagination.pages[pagination.pages.length - 1].query
        : "";
    const params = new URLSearchParams(
      lastPageQuery || lastPageInCollectionQuery
    );
    const lastPageOffset = +params.get("offset");

    return lastPageOffset / pagination.hitsPerPage + 1;
  }

  function constructArrayOfPageModels(totalPagesCount: number) {
    const pages: Page[] = [];
    for (let i = 0; i < totalPagesCount; i++) {
      const query = new URLSearchParams(
        searchResult.query != null || searchResult.query != undefined
          ? searchResult.query.parameters
          : searchResult.documentList.pagination.nextPage != null
          ? searchResult.documentList.pagination.nextPage.query
          : searchResult.documentList.pagination.pages.length > 0
          ? searchResult.documentList.pagination.pages[0].query
          : ""
      );
      query.set("offset", (i * pagination.hitsPerPage).toString());
      query.set("hits", pagination.hitsPerPage.toString());
      query.set("p", (i + 1).toString());
      pages.push({
        displayName: i.toString(),
        selected: false,
        query: query.toString(),
      });
    }
    return pages;
  }

  function onPageChange(event: { selected: number }) {
    doSearch(pages[event.selected].query);
    document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" });
  }

  // Build a href for the page anchor elements so paginate will work with JavaScript turned off
  const hrefBuilder = (pageIndex: number) => `?${pages[pageIndex - 1].query}`;

  /*********************************
   * Render
   *********************************/
  return (
    totalPagesCount > 1 && (
      <div className={cssClass + "__pagination-container"}>
        <div className={cssClass + "__pagination"}>
          <ReactPaginate
            renderOnZeroPageCount={null}
            initialPage={initialPageIndex}
            nextLabel={next}
            onPageChange={onPageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={totalPagesCount}
            previousLabel={previous}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination pagination-one-third-width"
            activeClassName="active"
            ariaLabelBuilder={(index) => `Sida ${index} av ${totalPagesCount}`}
            previousAriaLabel={"Föregående sida"}
            nextAriaLabel={"Nästa sida"}
            disableInitialCallback={true}
            hrefBuilder={hrefBuilder}
          />
        </div>
      </div>
    )
  );
};

const next = (
  <>
    <span className="prevnext">Nästa</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      aria-hidden="true"
      focusable="false"
      className="svg-next"
    >
      <use href="#streamline-arrow" />
    </svg>
  </>
);

const previous = (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      aria-hidden="true"
      focusable="false"
      className="svg-prev"
    >
      <use href="#streamline-arrow" />
    </svg>
    <span className="prevnext">Föregående</span>
  </>
);
