import React, { useContext, useState } from "react";
import { WizardContext } from "./store/wizard.store";
import { validateWizardFormData } from "./utils/ValidationUtil";

export const WizardPersonalDataStep: React.VFC<WizardPersonalDataStepProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [folkbokforingsUppgifter, setFolkbokforingsUppgifter] = useState<SkatteverketResponse>();
    const [showFolkbokforingsUppgifter, setShowFolkbokforingsUppgifter] = useState(false);
    const [showError, setShowError] = useState(false);

    const onChangeWizardFormData = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, [e.target.id]: e.target.value }))
    };

    const onCheckedWizardFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, [e.target.id]: e.target.checked }))
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
        const errors = validateWizardFormData(wizardFormData, e.target.id);

        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }))
    }

    const getPersonalInfo = async (e) => {
        const errors = validateWizardFormData({ ...wizardFormData, getInformationFromSkatteverket: true });
        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }))

        if (wizardFormData.getInformationFromSkatteverketValidationError) setWizardFormData((prevState) => ({ ...prevState, getInformationFromSkatteverketValidationError: "" }));
        if (wizardFormData.personalNumber != null && !errors["personalNumber"]) {
            const request = {
                method: "POST",
                headers: {
                    "Requestverificationtoken": props.antiforgeryToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(wizardFormData.personalNumber),
            };
            const response = await fetch(props.personalInfoUrl, request)
                .then(data => data.text());

            const result: SkatteverketResponse = JSON.parse(response);

            if (!result.error) {
                setFolkbokforingsUppgifter(JSON.parse(response));

                setWizardFormData((prevState) => ({ ...prevState, name: result.fornamn, lastName: result.efternamn, getInformationFromSkatteverket: true, validationErrors: {} }))
                setShowError(false);

                setShowFolkbokforingsUppgifter(true);
            } else {
                setShowError(true);

                const errors = validateWizardFormData({ ...wizardFormData, getInformationFromSkatteverket: true });

                setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }))
            }
        }
    }

    const describedBy = () => {
        if (wizardFormData.validationErrors["personalNumber"]) return "personalNumberError";
        if (wizardFormData.validationErrors["getInformationFromSkatteverket"]) return "getInformationFromSkatteverketError";
        return "";
    }

    return (
        <div className="ftvwebbooking__personal-data-step grid g-col-12">
            <div className="g-col-6 ftvwebbooking__header" style={{ rowGap: 0 }}>
                <div className="g-col-12 g-col-md-6">
                    <label htmlFor="personalNumber">Personnummer <span className="invalid" aria-hidden="true">*</span></label>
                    <input id="personalNumber" placeholder="ÅÅÅÅMMDDXXXX" value={wizardFormData.personalNumber} aria-invalid={(wizardFormData.validationErrors["getInformationFromSkatteverket"] || wizardFormData.validationErrors["personalNumber"]) ? "true" : "false"} aria-describedby={describedBy()} onChange={onChangeWizardFormData} onBlur={onBlur} autoComplete="off" required aria-label="Fyll i dina uppgifter."></input>
                    {wizardFormData.validationErrors["personalNumber"] && <span id="personalNumberError" className="invalid" aria-hidden="true">{wizardFormData.validationErrors["personalNumber"]}</span>}
                </div>
            </div>
            <div className="ftvwebbooking__header-button-container g-col-12 g-col-md-4">
                <button id="getInformationFromSkatteverket" className="button-primary" type="button" onClick={getPersonalInfo} disabled={Object.values(wizardFormData.validationErrors).length != 0 && (wizardFormData.validationErrors["personalNumber"] ? true : false)}>Hämta personuppgifter</button>
            </div>
            {wizardFormData.validationErrors["getInformationFromSkatteverket"] && <span id="getInformationFromSkatteverketError" className="g-col-12 invalid">{wizardFormData.validationErrors["getInformationFromSkatteverket"]}</span>}
            {showError && <span id="getInformationFromSkatteverketError" className="g-col-12 invalid"><p className="mb-0">Dina personuppgifter kunde inte hämtas, vänligen försök igen. Vid upprepade fel, kontakta kliniken via telefon eller 1177 e-tjänster för att boka tid.
                Du som har skyddade personuppgifter ska inte boka här.
            </p></span>}
            <span className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextGetPersonalData }}></span>
            {showFolkbokforingsUppgifter &&
                <div className="grid g-col-12">
                    <div className="g-col-6">
                        <label htmlFor="fornamn">Förnamn</label>
                        <input readOnly disabled id="fornamn" value={folkbokforingsUppgifter.fornamn}></input>
                    </div>
                    <div className="g-col-6">
                        <label htmlFor="efternamn">Efternamn</label>
                        <input readOnly disabled id="efternamn" value={folkbokforingsUppgifter.efternamn}></input>
                    </div>
                </div>}
            <div className="grid g-col-12 ftvwebbooking__personal-data">
                <div className="grid g-col-12">
                    <div className="g-col-12 g-col-md-6">
                        <label htmlFor="eMail">E-post <span className="invalid" aria-hidden="true">*</span></label>
                        <input type="email" id="eMail" autoComplete="email" value={wizardFormData.eMail} aria-invalid={wizardFormData.validationErrors["eMail"] ? "true" : "false"} aria-describedby={wizardFormData.validationErrors["eMail"] ? "eMailError" : ""} onChange={onChangeWizardFormData} onBlur={onBlur} required></input>
                        {wizardFormData.validationErrors["eMail"] && <span id="eMailError" className="invalid">{wizardFormData.validationErrors["eMail"]}</span>}
                    </div>
                    <div className="g-col-12 g-col-md-6">
                        <label htmlFor="mobilePhonenumber">Mobilnummer <span className="invalid" aria-hidden="true">*</span></label>
                        <input type="tel" id="mobilePhonenumber" autoComplete="tel" value={wizardFormData.mobilePhonenumber} aria-invalid={wizardFormData.validationErrors["mobilePhonenumber"] ? "true" : "false"} aria-describedby={wizardFormData.validationErrors["mobilePhonenumber"] ? "mobilePhonenumberError" : ""} onChange={onChangeWizardFormData} onBlur={onBlur} required></input>
                        {wizardFormData.validationErrors["mobilePhonenumber"] && <span id="mobilePhonenumberError" className="invalid">{wizardFormData.validationErrors["mobilePhonenumber"]}</span>}
                    </div>
                </div>
                <div className="g-col-12 g-col-md-6">
                    <label htmlFor="messageForClinic">Information till kliniken <span className="invalid" aria-hidden="true">*</span></label>
                    <div dangerouslySetInnerHTML={{ __html: props.infoTextMessageToClinic }}></div>
                    <textarea id="messageForClinic" value={wizardFormData.messageForClinic} aria-invalid={wizardFormData.validationErrors["messageForClinic"] ? "true" : "false"} aria-describedby={wizardFormData.validationErrors["messageForClinic"] ? "messageForClinicError" : ""} onChange={onChangeWizardFormData} onBlur={onBlur} rows={10} cols={50} maxLength={200} required></textarea>
                    {wizardFormData.validationErrors["messageForClinic"] && <span id="messageForClinicError" className="invalid">{wizardFormData.validationErrors["messageForClinic"]}</span>}
                    <br /><small>Max 200 tecken.</small>
                </div>
                <div className="g-col-12">
                    <strong>Bekräftelse via e-post och SMS</strong>
                    <p>Du kommer få en bokningsbekräftelse via e-post. Ange om du även vill ha den som SMS.</p>
                    <div className="ftvwebbooking__checkboxes">
                        <input type="checkbox" id="confirmationInSms" value={wizardFormData.confirmationInSms.toString()} checked={wizardFormData.confirmationInSms} onChange={onCheckedWizardFormData} required></input>
                        <label htmlFor="confirmationInSms">Bekräftelse via SMS</label>
                    </div>
                </div>
            </div>
        </div >
    );
}