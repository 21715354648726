export const calculateWeeks = (request: WizardSearchRequest) => {
    const selectedDateTimeFrom = request.selectedDate;
    const selectedDateTimeTo = new Date(selectedDateTimeFrom);

    selectedDateTimeTo.setMonth(selectedDateTimeTo.getMonth() + 3);

    if (!request.activateSmartSearch) return { currentWeekStart: selectedDateTimeFrom, currentWeekEnd: selectedDateTimeTo, weeks: 1 };

    const difference = selectedDateTimeTo.getTime() - selectedDateTimeFrom.getTime();
    const weeks = Math.ceil(difference / (7 * 24 * 60 * 60 * 1000));

    let currentWeekStart = new Date(selectedDateTimeFrom);
    let currentWeekEnd = setToClosestMidnight(new Date(currentWeekStart));
    currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

    return { currentWeekStart, currentWeekEnd, weeks };
}

function setToClosestMidnight(date: Date): Date {
    const midnight = new Date(date);
    midnight.setHours(0, 0, 0, 0);
    return midnight;
}