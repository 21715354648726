import { useContext, useEffect, useRef } from "react";
import { WizardContext } from "./store/wizard.store";

export const WizardConfirmationStep: React.VFC = () => {
    let { wizardFormData } = useContext(WizardContext);
    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.focus();
        }
    }, []);

    return (
        <div className="grid g-col-12">
            <div className="visually-hidden"
                ref={messageRef}
                tabIndex={-1}
                aria-live="polite"
            >
                Bekräfta dina uppgifter.
            </div>
            <input type="hidden" name="currentPageId" value={wizardFormData.currentPageId}></input>
            <input type="hidden" name="selectedTreatmentTypeDescription" value={wizardFormData.selectedTreatmentType.description}></input>
            <input type="hidden" name="serializedTimeSlot" value={JSON.stringify(wizardFormData.selectedTimeSlot)}></input>
            <input type="hidden" name="personalNumber" value={wizardFormData.personalNumber}></input>
            <input type="hidden" name="eMail" value={wizardFormData.eMail}></input>
            <input type="hidden" name="mobilePhonenumber" value={wizardFormData.mobilePhonenumber}></input>
            <input type="hidden" name="altPhonenumber" value={wizardFormData.altPhonenumber}></input>
            <input type="hidden" name="messageForClinic" value={wizardFormData.messageForClinic}></input>
            <input type="hidden" name="confirmationInSms" value={wizardFormData.confirmationInSms.toString()}></input>
            <input type="hidden" name="getInformationFromSkatteverket" value={wizardFormData.getInformationFromSkatteverket.toString()}></input>
            <input type="hidden" name="isBookingWithCode" value={wizardFormData.isBookingWithCode.toString()}></input>
            <div className="g-col-12 g-col-md-6">
                {!wizardFormData.isBookingWithCode &&
                    <>
                        <strong>Bokning:</strong>
                        <p>{wizardFormData.selectedTreatmentType.description}</p>
                    </>}
                <strong>Datum och tid:</strong>
                <p>
                    {' '}{new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getDate() + ' ' + new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).toLocaleString('sv-SE', { month: 'long' })}{' '}{new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getFullYear()}
                    {' kl. '}{new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getHours() + ":" + (new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes() < 10 ? new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes() + '0' : new Date(Date.parse(wizardFormData.selectedTimeSlot.startTime)).getMinutes())}
                </p>
                <strong>Behandlare:</strong>
                <p>{wizardFormData.selectedTimeSlot.performerName}</p>
                <strong>Klinik:</strong>
                <p>{wizardFormData.selectedTimeSlot.healthcareFacilityName}</p>
                {wizardFormData.messageForClinic && <strong>Information till kliniken:</strong>}
                <p>{wizardFormData.messageForClinic}</p>
                <strong>Bekräftelse via:</strong>
                {wizardFormData.confirmationInSms ? <p>E-post och SMS</p> : <p>E-post</p>}
            </div>
            <div className="g-col-12 g-col-md-6">
                <div className="ftvwebbooking__confirmation-wrapper">
                    <div>
                        <strong>Mina personuppgifter</strong>
                    </div>
                    <p>{wizardFormData.personalNumber.slice(0, -4) + '****'}</p>
                    <p>{wizardFormData.name + ' ' + wizardFormData.lastName}</p>
                    <p>{wizardFormData.mobilePhonenumber}</p>
                    <p>{wizardFormData.eMail}</p>
                </div>
            </div>
        </div>
    )
}