export const validateWizardFormData = (formData: WizardFormData, id?: string, isBookingWithCode?: boolean): Record<string, string> => {
    const errors: Record<string, string> = id ? { ...formData.validationErrors } : {};
    if (id && formData.validationErrors[id]) {
        delete errors[id];
    }

    const personalNumberRegEx = /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/;
    const eMailRegEx = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;
    const phoneNumberRegEx = /^[0]{1}[7]{1}[0-9]{8}$/;

    //First validate that the field is not empty
    if (!formData[id] && id && id !== "getInformationFromSkatteverket") errors[id] = "Fältet är obligatoriskt";
    //Then validate the individual conditions
    else if (errors[id]) delete errors[id];
    else if (id === "personalNumber" && !personalNumberRegEx.test(formData[id].trim())) errors[id] = "Fältet måste innehålla ett giltigt personnummer";
    else if (id === "personalNumber" && !formData.isBookingWithCode && !isBookingWithCode && parseInt(formData[id].trim().substring(0, 4)) > new Date().getFullYear() - 20) errors[id] = formData.under20ValidationError;
    else if (id === "eMail" && !eMailRegEx.test(formData[id].trim())) errors[id] = "Fältet måste innehålla en giltig epost";
    else if (id === "mobilePhonenumber" && !phoneNumberRegEx.test(formData[id].trim())) errors[id] = "Fältet måste innehålla ett giltigt telefonnummer";
    else if (!id) {
        if (!formData["getInformationFromSkatteverket"]) errors["getInformationFromSkatteverket"] = formData.getInformationFromSkatteverketValidationError;
    }

    return errors;
}